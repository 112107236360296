<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <v-card v-if="isViewReady && !isError && partnerName && partnerDomain">
                <v-toolbar dense flat color="teal" dark>
                    <v-toolbar-title>Connect your BrandProfile</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-progress-linear color="teal" height="8" :value="progress" striped class="mb-6">
                </v-progress-linear>
                <!-- TODO: do we really need the steps? maybe can remove this conditional and the other steps -->
                <v-card-text>
                    <p>{{ partnerName }} ({{ partnerDomain }}) wants to verify your BrandProfile.</p>
                    <!-- <v-form @submit.prevent="signup" onSubmit="return false;" @keyup.enter.native.prevent="signup" class="mx-4 pb-6">
                        <v-text-field v-model="username" ref="usernameInput" dense solo color="teal" hint="This will be your username. You can change it later." placeholder="Email" :error-messages="inputError">
                            <template #prepend-inner>
                                <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/>
                            </template>
                        </v-text-field>
                        <v-row no-gutters justify="center">
                        <v-btn @click="signup" elevation="4" color="teal white--text" class="mt-4">Continue</v-btn>
                        </v-row>
                    </v-form> -->
                    <template v-if="isAuthenticated && accountId && brandId">
                        <!-- TODO: show account name -->
                        <!-- TODO: show brand name and image -->
                        <v-btn @click="returnToPartner" color="teal white--text">Continue</v-btn>
                    </template>
                    <template v-if="isAuthenticated && accountId && !brandId">
                        <!-- TODO: if authenticated and account selected, show list of brandprofiles and maybe + sign to create a new one -->
                        <p>Select a brand to continue:</p>
                        <v-list dense>
                            <v-list-item v-if="brandList.length === 0">No accounts.</v-list-item>
                            <v-list-item v-for="item in brandList" v-bind:key="item.id" class="my-2" @click="selectBrand(item.id)">
                                <v-list-item-content class="text-start">
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </template>
                    <template v-if="isAuthenticated && !accountId">
                        <!-- TODO: if authenticatd, but account not selected, show account list to select an account , and maybe + sign to create a new one -->
                        <p>Select an account to continue:</p>
                        <v-list dense>
                            <v-list-item v-if="accountList.length === 0">No accounts.</v-list-item>
                            <v-list-item v-for="item in accountList" v-bind:key="item.id" class="my-2" @click="selectAccount(item.id)">
                                <v-list-item-content class="text-start">
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </template>
                    <template v-if="isAuthenticated">
                        <!-- TODO:  if authenticated, show "switch accounts" link to let user sign out and then sign in as someone else -->
                        <!-- TODO: maybe show this as an icon at the top of the card? -->
                    </template>
                    <template v-if="!isAuthenticated">
                        <!-- TODO: if not authenticated, show sign in and sign up link; OR show logincard under this card and just show text here about you have to be authenticated to continue -->
                        <p>You must be signed in to continue.</p>
                        <v-btn :to="loginRoute" elevation="2" color="teal white--text" class="my-6" outlined>Sign in</v-btn>
                        <p>No account yet? <router-link :to="signupRoute">Sign up</router-link></p>
                    </template>
                </v-card-text>
            </v-card>
            <p class="mb-15"></p>
            <v-alert type="error" v-if="serverError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="requestError">
                An error occurred while processing your request. Please try again. If the problem continues, please contact customer support.
            </v-alert>
            <v-alert type="error" v-if="forbiddenError">
                The link is expired or invalid. Check that the email you entered is correct and try again.
            </v-alert>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
// import { toText } from '@libertyio/time-util-js';
import { mapState } from 'vuex';
// import { isValidEmail, isValidProductLookupKey } from '@/sdk/input';
// import BrandList from '@/components/BrandList.vue';

export default {
    components: {
        // BrandList,
    },
    data: () => ({
        isViewReady: false,
        steps: ['authenticate', 'select_account', 'select_brand', 'confirm'],
        partnerName: null,
        partnerBrandProfile: null,
        partnerDomain: null,
        serverError: false,
        accountId: null, // user selected account id
        accountList: null, // user's linked accounts
        brandId: null, // user selected brand id
        brandList: null, // brands in selected account
        // OLD VARIABLES BELOW THIS POINT
        username: '',
        displayName: '',
        product: null,
        submitTimestamp: null,
        redirect: null,
        organizationList: null,
        // older ones, check if we're using
        interactionId: null,
        verificationExpires: null,
        serverErrorTimeout: null,
        requestError: false,
        requestErrorTimeout: null,
        inputError: null,
        inputErrorTimeout: null,
        forbiddenError: null,
        forbiddenErrorTimeout: null,
        registered: false,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
            focus: (state) => state.focus,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        // mainWebsiteURL() {
        //     return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://brandprofile.org';
        // },
        step() {
            if (!this.isAuthenticated) {
                return 'authenticate';
            }
            if (!this.accountId) {
                return 'select_account';
            }
            if (!this.brandId) {
                return 'select_brand';
            }
            return 'confirm';
        },
        progress() {
            if (this.steps.length === 0) {
                return 0;
            }
            const idx = this.steps.findIndex((value) => value === this.step);
            return Math.ceil(((idx + 1) * 100) / this.steps.length);
        },
        isError() {
            return this.serverError || this.requestError || this.inputError || this.forbiddenError;
        },
        loginRoute() {
            return { name: 'login', query: { next: this.$router.currentRoute.fullPath } };
        },
        signupRoute() {
            return { name: 'signup' };
        },
    },
    watch: {
        focus() {
            this.init();
        },
    },
    methods: {
        resetErrors() {
            this.serverError = false;
            if (this.serverErrorTimeout) {
                clearTimeout(this.serverErrorTimeout);
                this.serverErrorTimeout = null;
            }
            this.requestError = false;
            if (this.requestErrorTimeout) {
                clearTimeout(this.requestErrorTimeout);
                this.requestErrorTimeout = null;
            }
            this.inputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.forbiddenError = false;
            if (this.forbiddenErrorTimeout) {
                clearTimeout(this.forbiddenErrorTimeout);
                this.forbiddenErrorTimeout = null;
            }
            this.redirect = null;
            this.verificationExpires = null;
        },
        async connect() {
            try {
                this.$store.commit('loading', { connect: true });
                this.resetErrors();
                const interaction = await this.$client.main().interaction.get(this.$route.query.i);
                const { type } = interaction;
                if (type !== 'partner_connect') {
                    this.serverError = true;
                    this.isViewReady = true;
                    return;
                }
                this.partnerName = interaction.partner_name;
                this.partnerBrandProfile = interaction.partner_brandprofile;
                this.partnerDomain = interaction.partner_domain;

                if (this.isAuthenticated) {
                    await this.loadAccountList();
                }

                if (this.$route.query.accountId) {
                    await this.selectAccount(this.$route.query.accountId);
                }

                if (this.$route.query.brandId) {
                    await this.selectBrand(this.$route.query.brandId);
                }

                /*
                if (typeof this.username !== 'string' || this.username.trim().length === 0 || !isValidEmail(this.username)) {
                    this.inputError = 'Please enter an email address';
                    this.inputErrorTimeout = setTimeout(() => { this.inputError = null; }, 15000); // clear message in 15 seconds
                    return;
                }
                this.$store.commit('loading', { signup: true });
                const request = {
                    email: this.username,
                    display_name: this.displayName,
                    interactionId: this.interactionId, // null on first request, possibly a value after email verification (but not necessarily)
                    product: this.product, // may have a value if user arrived here by selecting a something on the pricing page
                };
                const response = await this.$client.main().authn.signup(request);
                // const response = await this.$client.main().authn.startVerifyEmail({ email: this.username, intent: 'signup' });
                console.log(`Signup.vue: response ${JSON.stringify(response)}`);
                if (response?.status) {
                    switch (response.status) {
                    case 'redirect':
                        if (response.redirect) {
                            // show a link after 2 seconds in case auto-redirect fails
                            setTimeout(() => {
                                this.redirect = response.redirect;
                            }, 2000);
                            // use replace so that when user taps 'back' button from there, they won't
                            // end up being redirected again to where they just wanted to come back from
                            if (typeof window.location.replace === 'function') {
                                window.location.replace(response.redirect);
                            } else {
                                // TODO: also show link for user to click
                                window.location.href = response.redirect;
                            }
                            return;
                        }
                        console.error('signup error: server redirect response missing redirect url');
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                        break;
                    case 'display_name':
                        // prompt user to enter display name
                        this.step = 'display_name';
                        this.$router.replace({ name: 'signup', query: { ...this.$route.query, step: 'display_name', t: Date.now() } });
                        this.$nextTick(() => {
                            setTimeout(() => { this.activate('displayNameInput'); }, 1);
                        });
                        break;
                    case 'authenticated':
                        await this.$store.dispatch('refresh');
                        // user is already authenticated with same email address, so complete account setup steps
                        this.step = 'notice';
                        this.$router.replace({ name: 'signup', query: { ...this.$route.query, step: 'notice', t: Date.now() } });
                        break;
                    case 'login_required':
                        this.registered = true;
                        this.step = 'verify_email';
                        break;
                    case 'error':
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                        break;
                    default:
                        console.error(`signup error: unexpected status from server: ${JSON.stringify(response.status)}`);
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    console.error('signup error: server response missing status');
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                }
                */
                this.isViewReady = true;
            } catch (err) {
                console.error('failed to sign up', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { connect: false });
                this.isViewReady = true;
            }
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        switchAccount() {
            this.resetErrors();
            this.step = 'start';
            this.$router.replace({ name: 'signup', query: { ...this.$route.query, step: 'start', t: Date.now() } });
            this.$nextTick(() => {
                setTimeout(() => { this.activate('usernameInput'); }, 1);
            });
        },
        async loadAccountList() {
            try {
                this.$store.commit('loading', { loadAccountList: true });
                const result = await this.$client.user(this.user.id).user.getAccountList();
                this.accountList = result.list;
            } catch (err) {
                console.error('failed to load organization list', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { loadAccountList: false });
            }
        },
        async loadBrandList({ accountId }) {
            try {
                this.$store.commit('loading', { loadBrandList: true });
                const response = await this.$client.account(accountId).brand.search({});
                if (response?.list) {
                    this.brandList = response.list;
                }
            } catch (err) {
                console.error('loadBrandList failed', err);
            } finally {
                this.$store.commit('loading', { loadBrandList: false });
            }
        },
        async selectAccount(accountId) {
            const account = this.accountList.find((item) => item.id === accountId);
            if (!account) {
                this.requestError = true;
                return;
            }
            this.accountId = accountId;
            this.brandId = null;
            this.brandList = null;
            this.brandprofile = null;
            await this.loadBrandList({ accountId });
            if (accountId === this.$route.query.accountId) {
                return;
            }
            const query = { ...this.$route.query, accountId };
            this.$router.push({ name: 'partner-connect', query });
        },
        async selectBrand(brandId) {
            const brand = this.brandList.find((item) => item.id === brandId);
            if (!brand) {
                this.requestError = true;
                return;
            }
            this.brandId = brandId;
            this.brandprofile = brand.alias;
            // TODO: load brand image
            if (brandId === this.$route.query.brandId) {
                return;
            }
            const query = { ...this.$route.query, brandId };
            this.$router.push({ name: 'partner-connect', query });
        },
        async returnToPartner() {
            try {
                this.$store.commit('loading', { returnToPartner: true });
                const response = await this.$client.account(this.accountId).partnerConnect.confirm({ interaction_id: this.interactionId, brandprofile: this.brandprofile });
                if (response?.redirect) {
                    if (typeof window.location.push === 'function') {
                        window.location.push(response.redirect);
                    } else {
                        window.location.href = response.redirect;
                    }
                }
            } catch (err) {
                console.error('loadBrandList failed', err);
            } finally {
                this.$store.commit('loading', { returnToPartner: false });
            }
        },
    },
    mounted() {
        /*
        // if user arrives with `email` and `step=display_name`, show the email address and
        // ask user for their display name
        if (this.$route.query.step === 'display_name' && this.$route.query.email) {
            this.step = 'display_name';
            this.username = this.$route.query.email;
            this.isViewReady = true;
            this.$nextTick(() => {
                setTimeout(() => { this.activate('displayNameInput'); }, 1);
            });
            return;
        }

        // if user arrives with `email` and `step=notice`, check that user is authenticated
        // and has the same email address and show that step, otherwise start over
        if (this.$route.query.step === 'notice' && this.$route.query.email) {
            this.step = 'notice';
            this.username = this.$route.query.email;
            this.isViewReady = true;
            return;
        }
        */

        // when the user arrives at this page there should already be an interaction id in the query
        if (!this.$route.query.i) {
            this.serverError = true;
            this.isViewReady = true;
            return;
        }

        this.interactionId = this.$route.query.i;
        this.connect();
        // this.$nextTick(() => {
        //     setTimeout(() => { this.activate('usernameInput'); }, 1);
        // });
    },
};
</script>
